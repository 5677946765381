const spacing = {
  1: 4,
  2: 8,
  3: 16,
  4: 24,
  5: 32,
  6: 64,
  7: 88,
} as const;

export default spacing;
